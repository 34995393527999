import React from "react"
import Footer from "./Footer"
import Header from "./Header"

export default ({
  children,
  small,
  nav,
  headingData,
  footer,
  motherCreditLogo,
}) => (
  <>
    <Header
      small={small}
      nav={nav}
      headingData={headingData}
      motherCreditLogo={motherCreditLogo}
    />
    {children}
    <Footer footer={footer} />
  </>
)
