import React, { useEffect } from "react"
import { Link } from "gatsby"

export default ({ footer }) => {

  useEffect(()=>{
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml            : true,
        version          : 'v13.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, [])

  return (
    <>
      <hr />
      <footer className="footer text-center">
        <div className="footer-links">
          {footer.map((link, index) => (
            <Link key={index} to={link.url}>
              {link.name}
            </Link>
          ))}
        </div>
        <div>Copyright©2018-{new Date().getFullYear()}</div>
        <div id="fb-root" />
        <div
          className="fb-customerchat"
          attribution="biz_inbox"
          page_id="2006800912724495"
        />
      </footer>
    </>
  )
}
