import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"



export default ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      className={className}
      fixed={data.placeholderImage.childImageSharp.fixed}
      style={{ verticalAlign: "middle" }}
    />
  )
}
