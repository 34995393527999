import React from "react"
import Nav from "./Nav"
import Logo from "./Logo"
import MotherCreditLogo from "./MotherCreditLogo"

export default ({ nav, small, headingData, motherCreditLogo }) => {
  return (
    <>
      <section id="home" className={small ? "home-small" : "home"}>
        <Nav nav={nav} small={small} motherCreditLogo={motherCreditLogo}></Nav>
        {!small && (
          <>
            {motherCreditLogo ? (
              <MotherCreditLogo width={350} />
            ) : (
              <Logo width={250} />
            )}
            <h1>{headingData[0]}</h1>
            <h3>{headingData[1]}</h3>
            <h5>{headingData[2]}</h5>
          </>
        )}
      </section>
    </>
  )
}
