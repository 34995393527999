import React, { useState } from "react"
import { Link } from "gatsby"
import SmallLogo from "./SmallLogo"
import MMFlag from "./MMFlag"
import USFlag from "./USFlag"
import MotherCreditSmallLogo from "./MotherCreditSmallLogo"

const ListLink = ({ to, children }) => (
  <li>
    <Link to={to} className="navList">
      {children}
    </Link>
  </li>
)

export default ({ nav, small, motherCreditLogo }) => {
  const [showHide, setShowHide] = useState(false)
  const handleShowHide = () => {
    setShowHide(!showHide)
  }

  return (
    <>
      <div className="d-md-block d-lg-none text-right px-3">
        <span className="mobile-menu-button">
          <i
            className="fas fa-bars"
            onClick={handleShowHide}
            onKeyDown={handleShowHide}
            role="button"
            tabIndex={0}
            aria-label="Show Hide Menu"
          ></i>
        </span>
      </div>
      <div>
        <nav
          className={
            showHide
              ? "container-fluid main-nav animate show"
              : "container-fluid main-nav"
          }
        >
          <div className="d-md-block d-lg-none text-right">
            <span className="mobile-menu-button">
              <i
                className="fas fa-times"
                onClick={handleShowHide}
                onKeyDown={handleShowHide}
                role="button"
                tabIndex={0}
                aria-label="Show Hide Menu"
              ></i>
            </span>
          </div>
          <div className="col-12">
            <ul>
              {motherCreditLogo ? (
                ""
              ) : (
                <ListLink
                  to="/"
                  className={small ? "d-lg-block" : "d-sm-block"}
                >
                  <SmallLogo width={80} className={"logo-img"} />
                </ListLink>
              )}
              {nav.map(({ name, url }, index) => (
                <ListLink key={index} to={url}>
                  {name === "motherCredit" ? (
                    <MotherCreditSmallLogo width={80} className={"logo-img"} />
                  ) : (
                    name
                  )}
                </ListLink>
              ))}

              <li className="language-switcher">
                <Link to="/mm">
                  <MMFlag />
                </Link>
                <Link to="/en">
                  <USFlag />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  )
}
